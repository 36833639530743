import { format } from 'date-fns'
import { WorkPostSheetFormValues } from '../providers/FormProvider'
import { WorkPostSheetInput, WorkPostSheetItemTypesEnum } from '../types'
import { isDateWPSItem } from './isDateWPSItem'
import { isMaternityProtectionMeasuresWPSItem } from './isMaternityProtectionMeasuresWPSItem'
import { isTextWPSItem } from './isTextWPSItem'

export const wpsFormValuesToWorkPostSheetInput = (
  values: WorkPostSheetFormValues
): WorkPostSheetInput => {
  // custom PDF uploading
  if (values.uploadOwnWPS && values.newPdfCommentAttachment) {
    return {
      tasksToPerform: null,
      requiredQualifications: null,
      welcomeOfficer: null,
      welcomeOfficerPosition: null,
      workEquipment: null,
      priorInstructions: null,
      foreseeableTraining: null,
      workstationLocation: null,
      items: [
        {
          name: WorkPostSheetItemTypesEnum.PDF_URL,
          commentAttachment: {
            fileName: values.newPdfCommentAttachment.fileName,
            base64: values.newPdfCommentAttachment.base64
          }
        }
      ]
    }
  }

  return {
    tasksToPerform: values.tasksToPerform || null,
    requiredQualifications: values.requiredQualifications || null,
    welcomeOfficer: values.welcomeOfficer || null,
    welcomeOfficerPosition: values.welcomeOfficerPosition || null,
    workEquipment: values.workEquipment || null,
    priorInstructions: values.priorInstructions || null,
    foreseeableTraining: values.foreseeableTraining || null,
    workstationLocation: values.workstationLocation || null,
    items: values.items
      .filter(({ checked, comment, name }) => {
        if (
          isMaternityProtectionMeasuresWPSItem(name) &&
          !values.maternityProtectionMeasuresRequired
        ) {
          return false
        }

        if (isDateWPSItem(name) || isTextWPSItem(name)) {
          return !!comment
        }
        return checked
      })
      .map(({ name, comment }) => {
        let formattedComment = comment

        if (comment && isDateWPSItem(name) && typeof comment !== 'string') {
          formattedComment = format(comment, 'yyyy-MM-dd')
        }

        return {
          name,
          ...(formattedComment && { comment: String(formattedComment) })
        }
      })
      .filter(item => item.name !== WorkPostSheetItemTypesEnum.PDF_URL)
  }
}
