/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

import { Date } from '../../scalars/Date'
import { DateTime } from '../../scalars/DateTime'

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ContractReasonsEnum {
  INSTROOM = 'INSTROOM',
  TIJDELIJKE_VERMEERDERING_VAN_WERK = 'TIJDELIJKE_VERMEERDERING_VAN_WERK',
  UITZONDERLIJK_WERK = 'UITZONDERLIJK_WERK',
  VERVANGING_VOOR_EINDE_VAN_OVEREENKOMST = 'VERVANGING_VOOR_EINDE_VAN_OVEREENKOMST',
  VERVANGING_VOOR_SCHORSING_VAN_OVEREENKOMST = 'VERVANGING_VOOR_SCHORSING_VAN_OVEREENKOMST'
}

export enum GendersEnum {
  FEMALE = 'FEMALE',
  MALE = 'MALE'
}

export enum MaritalStatesEnum {
  DIVORCED = 'DIVORCED',
  LEGALLY_DIVORCED = 'LEGALLY_DIVORCED',
  LIVING_TOGETHER = 'LIVING_TOGETHER',
  MARRIED = 'MARRIED',
  NOT_MARRIED = 'NOT_MARRIED',
  WIDOW = 'WIDOW'
}

export enum OvertimePayingStatusesEnum {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  NOT_PAID = 'NOT_PAID',
  PAID = 'PAID'
}

export enum PositionApprovalStatesEnum {
  APPROVED = 'APPROVED',
  WAITING_APPROVAL = 'WAITING_APPROVAL'
}

export enum PremiumUnitsEnum {
  PERCENT = 'PERCENT',
  PER_DAY = 'PER_DAY',
  PER_HOUR = 'PER_HOUR',
  PER_KILOMETER = 'PER_KILOMETER',
  PER_WEEK = 'PER_WEEK'
}

export enum WorkPostSheetItemTypesEnum {
  ADVICE_BY_COMITE_PWB = 'ADVICE_BY_COMITE_PWB',
  ADVICE_BY_DIRECT_EMPLOYEE_PARTICIPATION = 'ADVICE_BY_DIRECT_EMPLOYEE_PARTICIPATION',
  ADVICE_BY_SYNDICATE_REP = 'ADVICE_BY_SYNDICATE_REP',
  ADVICE_DATE = 'ADVICE_DATE',
  ADVICE_DATE_INTERNAL_SERVICE = 'ADVICE_DATE_INTERNAL_SERVICE',
  ADVICE_DATE_LABOR_DOCTOR = 'ADVICE_DATE_LABOR_DOCTOR',
  BIOLOGICAL_AGENTS = 'BIOLOGICAL_AGENTS',
  BREASTFEEDING_ADJUSTEMENTS_DATE = 'BREASTFEEDING_ADJUSTEMENTS_DATE',
  BREASTFEEDING_ADJUSTMENTS = 'BREASTFEEDING_ADJUSTMENTS',
  CHEMICAL_AGENTS = 'CHEMICAL_AGENTS',
  COAT = 'COAT',
  DUST_JACKET = 'DUST_JACKET',
  ERGONOMIC_LOAD = 'ERGONOMIC_LOAD',
  FUNCTION_WITH_INCREASED_VIGILANCE = 'FUNCTION_WITH_INCREASED_VIGILANCE',
  GLASSES_SAFETY_SCREEN = 'GLASSES_SAFETY_SCREEN',
  GLOVES_MITTENS = 'GLOVES_MITTENS',
  HANDLING_LOADS = 'HANDLING_LOADS',
  HEARING_PROTECTION = 'HEARING_PROTECTION',
  HELMET = 'HELMET',
  HEPATITIS_B = 'HEPATITIS_B',
  MASK = 'MASK',
  NIGHT_WORK = 'NIGHT_WORK',
  NOISE = 'NOISE',
  OINTMENTS = 'OINTMENTS',
  OTHER_EQUIPMENT = 'OTHER_EQUIPMENT',
  OTHER_VACCINATIONS_TESTS = 'OTHER_VACCINATIONS_TESTS',
  PANTS_OVERALL = 'PANTS_OVERALL',
  PDF_URL = 'PDF_URL',
  PREGNANCY_ADJUSTMENTS = 'PREGNANCY_ADJUSTMENTS',
  PREGNANCY_ADJUSTMENTS_DATE = 'PREGNANCY_ADJUSTMENTS_DATE',
  SAFETY_BELT_HARNESS = 'SAFETY_BELT_HARNESS',
  SAFETY_FUNCTION = 'SAFETY_FUNCTION',
  SAFETY_SHOES = 'SAFETY_SHOES',
  SHIFT_WORK = 'SHIFT_WORK',
  TETANUS = 'TETANUS',
  TUBERCULOSE = 'TUBERCULOSE',
  YOUNGER = 'YOUNGER'
}

/**
 * Autogenerated input type of AddUserToCompany
 */
export interface AddUserToCompanyInput {
  clientMutationId?: string | null
  companyId: string
  user: UserInput
}

export interface AttachmentInput {
  base64: string
  fileName: string
}

export interface CompanyInput {
  buildingNumber?: string | null
  city?: string | null
  logoAttachment?: AttachmentInput | null
  name?: string | null
  street?: string | null
  streetNumber?: string | null
  zip?: string | null
}

/**
 * Autogenerated input type of ConfirmAgreements
 */
export interface ConfirmAgreementsInput {
  agreements: string[]
  clientMutationId?: string | null
}

export interface ContractInput {
  attemptsCount?: number | null
  buildingNumber?: string | null
  city?: string | null
  confirmedFullTime: boolean
  contractPremiums: PremiumValuesInput[]
  contractReason: ContractReasonsEnum
  countryId?: string | null
  latitude?: number | null
  longitude?: number | null
  remark?: string | null
  street: string
  streetNumber?: string | null
  vcaExamDate?: Date | null
  wage: number
  wageUpdateComment?: string | null
  workdays: WorkdayInput[]
  zip?: string | null
}

/**
 * Autogenerated input type of CreateContracts
 */
export interface CreateContractsInput {
  clientMutationId?: string | null
  contract: ContractInput
  employeeId: string
  positionId: string
}

/**
 * Autogenerated input type of CreateEmployeeCertificate
 */
export interface CreateEmployeeCertificateInput {
  certificate: EmployeeCertificateInput
  clientMutationId?: string | null
  employeeId?: string | null
}

/**
 * Autogenerated input type of CreatePosition
 */
export interface CreatePositionInput {
  clientMutationId?: string | null
  companyId?: string | null
  position: PositionInput
}

/**
 * Autogenerated input type of CreateWorkPostSheet
 */
export interface CreateWorkPostSheetInput {
  clientMutationId?: string | null
  positionId: string
  workPostSheet: WorkPostSheetInput
}

/**
 * Autogenerated input type of DeleteContract
 */
export interface DeleteContractInput {
  clientMutationId?: string | null
  id: string
}

/**
 * Autogenerated input type of DeleteEmployeeCertificate
 */
export interface DeleteEmployeeCertificateInput {
  clientMutationId?: string | null
  id: string
}

export interface EmployeeCertificateInput {
  certificateType: string
  expiresOn?: Date | null
  scannedDocumentBackUrlAttachment?: AttachmentInput | null
  scannedDocumentUrlAttachment?: AttachmentInput | null
}

export interface EmployeeInput {
  email?: string | null
  firstName?: string | null
  gender?: GendersEnum | null
  isStudent?: boolean | null
  lastName?: string | null
  mobile?: string | null
  preferredLanguage?: string | null
  profile?: EmployeeProfileInput | null
  ssn?: string | null
  workProfile?: EmployeeWorkProfileInput | null
}

export interface EmployeeProfileInput {
  birthdate?: Date | null
  buildingNumber?: string | null
  city?: string | null
  countryId?: string | null
  countryOfBirthId?: string | null
  idCardBackPictureAttachment?: AttachmentInput | null
  idCardExpiresOn?: Date | null
  idCardFrontPictureAttachment?: AttachmentInput | null
  idCardNumber?: string | null
  nationalityCountryId?: string | null
  pictureAttachment?: AttachmentInput | null
  placeOfBirth?: string | null
  street?: string | null
  streetNumber?: string | null
  zip?: string | null
}

export interface EmployeeWorkProfileInput {
  bankAccount?: string | null
  bic?: string | null
  childrenNumber?: number | null
  childrenWithDisabilitiesNumber?: number | null
  dependentPersonsNumber?: number | null
  dependentPersonsWithDisabilitiesNumber?: number | null
  involuntaryPartTime?: boolean | null
  maritalDate?: Date | null
  maritalState?: MaritalStatesEnum | null
  residencePermitBackPictureAttachment?: AttachmentInput | null
  residencePermitCode?: string | null
  residencePermitExpiresOn?: Date | null
  residencePermitFrontPictureAttachment?: AttachmentInput | null
  taxCode?: string | null
  workPermitBackPictureAttachment?: AttachmentInput | null
  workPermitExpiresOn?: Date | null
  workPermitFrontPictureAttachment?: AttachmentInput | null
}

/**
 * Autogenerated input type of FillCompanySurvey
 */
export interface FillCompanySurveyInput {
  answers: SurveyAnswerInput[]
  clientMutationId?: string | null
  surveyId: string
}

/**
 * Autogenerated input type of InviteToTeam
 */
export interface InviteToTeamInput {
  clientMutationId?: string | null
  companyId?: string | null
  email: string
  firstName: string
  lastName: string
  locale: string
}

/**
 * Properties for creating/updating position
 */
export interface PositionInput {
  certificateTypes: string[]
  city?: string | null
  countryId?: string | null
  description?: string | null
  ecoChequeComment?: string | null
  functionCodeId: string
  latitude?: number | null
  longitude?: number | null
  name: string
  nettoPremiumComment?: string | null
  noVatApplicable: boolean
  street: string
  streetNumber?: string | null
  studentsAllowed?: boolean | null
  workEquipmentRequired?: boolean | null
  wpsRequired?: boolean | null
  zip?: string | null
}

export interface PremiumValuesInput {
  contributionValue?: number | null
  premiumId: string
  unit: PremiumUnitsEnum
  value?: number | null
}

/**
 * Autogenerated input type of RemoveFromTeam
 */
export interface RemoveFromTeamInput {
  clientMutationId?: string | null
  companyId?: string | null
  employeeId: string
}

/**
 * Autogenerated input type of RevokeAccessToCompany
 */
export interface RevokeAccessToCompanyInput {
  clientMutationId?: string | null
  companyId: string
  userId: string
}

export interface SafetyAnswerInput {
  answer: string
  employeeId?: string | null
  positionId: string
}

/**
 * Autogenerated input type of SendInvitationReminder
 */
export interface SendInvitationReminderInput {
  clientMutationId?: string | null
  companyId: string
  employeeId: string
}

/**
 * Autogenerated input type of SendMissingContractInformationPushNotification
 */
export interface SendMissingContractInformationPushNotificationInput {
  clientMutationId?: string | null
  employeeId: string
}

/**
 * Autogenerated input type of SetPassword
 */
export interface SetPasswordInput {
  clientMutationId?: string | null
  password: string
  passwordConfirmation: string
}

/**
 * Autogenerated input type of SetPositionPremiums
 */
export interface SetPositionPremiumsInput {
  clientMutationId?: string | null
  id: string
  positionPremiums: PremiumValuesInput[]
}

/**
 * Autogenerated input type of SetSafetyAnswer
 */
export interface SetSafetyAnswerInput {
  clientMutationId?: string | null
  safetyAnswer: SafetyAnswerInput
}

export interface SurveyAnswerInput {
  boolValue?: boolean | null
  questionId: string
  textValue?: string | null
}

/**
 * Autogenerated input type of UpdateCompany
 */
export interface UpdateCompanyInput {
  clientMutationId?: string | null
  company: CompanyInput
  id?: string | null
}

/**
 * Autogenerated input type of UpdateContracts
 */
export interface UpdateContractsInput {
  clientMutationId?: string | null
  data: ContractInput
  ids: string[]
}

/**
 * Autogenerated input type of UpdateEmployeeCertificate
 */
export interface UpdateEmployeeCertificateInput {
  certificate: EmployeeCertificateInput
  clientMutationId?: string | null
  id: string
}

/**
 * Autogenerated input type of UpdateEmployee
 */
export interface UpdateEmployeeInput {
  clientMutationId?: string | null
  employee: EmployeeInput
  id?: string | null
}

/**
 * Autogenerated input type of UpdatePosition
 */
export interface UpdatePositionInput {
  clientMutationId?: string | null
  id: string
  position: PositionInput
}

/**
 * Autogenerated input type of UpdateUser
 */
export interface UpdateUserInput {
  clientMutationId?: string | null
  user: UserInput
}

export interface UserInput {
  email: string
  firstName: string
  lastName: string
  mobile?: string | null
  preferredLanguage?: string | null
  telephone?: string | null
}

/**
 * Properties for creating/updating work post sheet
 */
export interface WorkPostSheetInput {
  foreseeableTraining?: string | null
  items?: WorkPostSheetItemInput[] | null
  priorInstructions?: string | null
  requiredQualifications?: string | null
  welcomeOfficer?: string | null
  welcomeOfficerPosition?: string | null
  tasksToPerform?: string | null
  workEquipment?: string | null
  workstationLocation?: string | null
}

/**
 * Properties for creating/updating work post sheet item
 */
export interface WorkPostSheetItemInput {
  comment?: string | null
  commentAttachment?: AttachmentInput | null
  name: WorkPostSheetItemTypesEnum
}

export interface WorkdayInput {
  breakInMinutes: number
  endsAt: DateTime
  startsAt: DateTime
}

//==============================================================
// END Enums and Input Objects
//==============================================================
