import styled from '@emotion/styled'
import {
  Divider as AntdDivider,
  TextStylePreset,
  Typography
} from '@vivaldis/antd-ui'
import { useTheme } from '@vivaldis/ui'
import { FC, PropsWithChildren, ReactNode } from 'react'

export interface InformationFieldProps {
  label: ReactNode
  showDivider?: boolean
  smallDivider?: boolean
}

export const InformationField: FC<PropsWithChildren<InformationFieldProps>> = ({
  label,
  showDivider = true,
  smallDivider = false,
  children
}) => {
  const theme = useTheme()
  return (
    <>
      <FieldBase>
        <FieldLabel>
          <Typography.Text
            preset={TextStylePreset.Regular16}
            color={theme.gray7}
          >
            {label}
          </Typography.Text>
        </FieldLabel>
        <FieldValue>
          <Typography.Text preset={TextStylePreset.Regular16} textAlign="right">
            {children}
          </Typography.Text>
        </FieldValue>
      </FieldBase>
      {showDivider ? <Divider smallDivider={smallDivider} /> : null}
    </>
  )
}

const FieldBase = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`

const FieldLabel = styled('div')`
  display: flex;
  min-width: 80px;
`

const FieldValue = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 50px;
`

interface DividerProps {
  smallDivider?: boolean
}

const Divider = styled(AntdDivider, {
  shouldForwardProp: prop => prop !== 'smallDivider'
})<DividerProps>`
  border-top: 1px solid ${props => props.theme.gray4};
  ${props => (props.smallDivider ? 'margin: 8px 0;' : '')}
`
