import { parseISO } from 'date-fns'
import type { WorkPostSheetFormValues } from '../providers/FormProvider'
import { WorkPostSheet, WorkPostSheetItemTypesEnum } from '../types'
import { isDateWPSItem } from './isDateWPSItem'
import { isMaternityProtectionMeasuresWPSItem } from './isMaternityProtectionMeasuresWPSItem'

export const wpsToWPSFormValues = (
  workPostSheet: WorkPostSheet
): WorkPostSheetFormValues => {
  const allWPSItems = Object.values(WorkPostSheetItemTypesEnum).map(name => ({
    name,
    comment: '',
    checked: true
  }))
  const maternityProtectionMeasuresRequired =
    workPostSheet.items?.some(item => {
      if (isMaternityProtectionMeasuresWPSItem(item.name)) {
        return !!item.comment
      }
      return false
    }) || false

  const pdfUrl = workPostSheet.items?.find(
    item => item.name === WorkPostSheetItemTypesEnum.PDF_URL
  )?.comment

  return {
    uploadOwnWPS: !!pdfUrl,
    newPdfCommentAttachment: {
      fileName: '',
      base64: ''
    },
    existingPdfUrl: pdfUrl || undefined,
    tasksToPerform: workPostSheet.tasksToPerform || '',
    requiredQualifications: workPostSheet.requiredQualifications || '',
    welcomeOfficer: workPostSheet.welcomeOfficer || '',
    welcomeOfficerPosition: workPostSheet.welcomeOfficerPosition || '',
    workEquipment: workPostSheet.workEquipment || '',
    priorInstructions: workPostSheet.priorInstructions || '',
    foreseeableTraining: workPostSheet.foreseeableTraining || '',
    workstationLocation: workPostSheet.workstationLocation || '',
    maternityProtectionMeasuresRequired: maternityProtectionMeasuresRequired,
    items: allWPSItems.map(item => {
      let comment: string | Date =
        workPostSheet.items?.find(wpsItem => wpsItem.name === item.name)
          ?.comment || ''

      if (isDateWPSItem(item.name) && comment) {
        comment = parseISO(comment)
      }

      return {
        ...item,
        comment,
        checked: !!workPostSheet.items?.some(
          wpsItem => wpsItem.name === item.name
        )
      }
    })
  }
}
