import { gql } from '@apollo/client'
import { workPostSheetItemFragment } from './WorkPostSheetItemFragment'

export const workPostSheetFragment = gql`
  ${workPostSheetItemFragment}

  fragment WorkPostSheetFragment on WorkPostSheet {
    foreseeableTraining
    id
    items {
      ...WorkPostSheetItemFragment
    }
    priorInstructions
    requiredQualifications
    welcomeOfficer
    welcomeOfficerPosition
    tasksToPerform
    workEquipment
    workstationLocation
  }
`
